<script setup lang="ts">
import { Button } from '#components'
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const { modelValue, parentValue, handleChange } = useField(props, emit)

const { cast } = useFieldCast(props.node)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

const [value, toggle] = useToggle(modelValue.value)

let isUpdating = false
watch(
  () => modelValue.value,
  (v) => {
    if (!isUpdating) {
      isUpdating = true
      value.value = cast(v)
      nextTick(() => (isUpdating = false))
    }
  },
)

watch(value, (v) => {
  if (!isUpdating) {
    modelValue.value = v
    handleChange(v)
  }
})

// TODO: Validation
useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField :node="node">
    <template #default="{ id, label }">
      <Tooltip
        class="bg-gray-950 text-xs"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
        outer-class="grow flex"
      >
        <Button
          :id="id"
          :color="value === true ? 'primary' : 'secondary'"
          size="sm"
          @click="toggle()"
        >
          {{ label }}
        </Button>
      </Tooltip>
    </template>
  </ManagerField>
</template>
